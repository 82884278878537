import { defineStore } from 'pinia'
import { useCookieStore } from "~/store/cookie";

export const useCreditStore = defineStore({
  id: 'credit-store',
  state: () => {
    return {
      errors: null,
      stripeUrl: null,
      session: null,
      customer: null,
      updateError: false,
      credit_package: null,
      credit_purchases: [],
      credit_packages: [],
      fetchError: false,
    }
  },
  actions: {
     async createPurchaseSession(credit_package_id, config) {
      const cookieStore = useCookieStore();
      this.updateError = false;
      const {data, error} = await useFetch(config.public.externalApiBase +`/payments/create-credit-purchase-session/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + cookieStore.token,
        },
        body: {
          credit_package_id: credit_package_id,
        }
      });

      if (data.value) {
        this.stripeUrl = data.value.url;
      }
      else if (error.value) {
        this.updateError = true;
      }
    },
    async retrievePurchaseSession(session_id, config) {
      const cookieStore = useCookieStore();
      this.fetchError = false;
      const {data, error} = await useFetch(config.public.externalApiBase +`/payments/retrieve-credit-purchase-session/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + cookieStore.token,
        },
        params: {
          session_id: session_id,
        },
      });

      if (data.value) {
        this.session = data.value.session;
        this.customer = data.value.customer;
        this.credit_package = data.value.credit_package;
      }
      else if (error.value) {
        this.fetchError = true;
      }
    },
    async getCreditPurchases(config) {
      const cookieStore = useCookieStore();
      this.updateError = false;
      const {data, error} = await useFetch(config.public.externalApiBase +`/payments/my-credit-purchases/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + cookieStore.token,
        },
      });

      if (data.value) {
        this.credit_purchases = data.value.results;
      }
      else if (error.value) {
        this.updateError = true;
      }
    },
    async getCreditPackages(type, config) {
       let params = {};
        if (type) {
          params = {
            type: type,
          }
        }
       this.fetchError = false;
       const {data, error} = await useFetch(config.public.externalApiBase +`/payments/credit-packages/`, {
         method: 'GET',
         headers: {
           'Content-Type': 'application/json',
         },
         params: params,
       });

        if (data.value) {
          this.credit_packages = data.value.results;
        }
        else if (error.value) {
          this.fetchError = true;
        }
    }
  }
});